import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, ButtonBase, Typography } from '@mui/material';
import ContactImg from './contact-background.jpg';
import './styles.css';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  fontSize: '30px',
  fontWeight: '600',
  [theme.breakpoints.down('sm')]: {
    padding: '0 5vw',
  },
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.55,
  transition: theme.transitions.create('opacity'),
}));

export default function ContactUs() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        height: '300px',
      }}>
      <ImageButton
        focusRipple
        key="Contactez-nous !"
        style={{
          width: '100%',
          height: '300px',
        }}
        href="/contact">
        <ImageSrc
          style={{
            backgroundImage: `url(${ContactImg})`,
            backgroundPosition: 'bottom',
          }}
        />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <div>Vous avez un projet ?</div>
          <div className="texte-contact-us">
            <br />
            N'hésitez pas à rentrer en contact avec nous, pour que nous
            puissions vous faire part de notre expertise.
          </div>
          <br />
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: 'relative',
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              fontSize: '1.25rem',
              lineHeight: 2,
            }}
            class="case">
            CONTACTEZ-NOUS
          </Typography>
        </Image>
      </ImageButton>
    </Box>
  );
}
