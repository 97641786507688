import './styles.css';

const Presentation = () => {
  return (
    <div className="background-presentation">
      <div className="background">
        <h1 className="title-presentation">
          INFORMATIQUE &ensp; TELECOM &ensp; IMPRESSION
        </h1>
        <p className="description-presentation">
          Concentrez-vous sur votre activité, nous nous occupons du reste.
        </p>
      </div>
    </div>
  );
};

export default Presentation;
