import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './styles.css';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';

const Footer = () => {
  const theme = useTheme();
  const smallScreenMode = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="footer">
      <div className="footer1">
        <div className="contactPro">
          <div className="contactPro-title">ADRESSE</div>
          <div className="contactPro-info">
            <LocationOnIcon />
            <p className="contactPro-text">
              2 rue Mathieu {!smallScreenMode && <br />} 93400 Saint-Ouen
            </p>
          </div>
        </div>
        <div className="contactPro">
          <div className="contactPro-title">CONTACT</div>
          {smallScreenMode ? (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1vh' }}>
              <div className="contactPro-info">
                <EmailOutlinedIcon />
                <a
                  href="mailto:anakache@2a-consulting.fr"
                  className="contactPro-text">
                  contact@2a-consulting.fr
                </a>
              </div>
              <div className="contactPro-info">
                <LocalPhoneOutlinedIcon />
                <p className="contactPro-text">01.86.90.63.85</p>
              </div>
            </div>
          ) : (
            <>
              <div className="contactPro-info">
                <EmailOutlinedIcon />
                <a
                  href="mailto:anakache@2a-consulting.fr"
                  className="contactPro-text">
                  contact@2a-consulting.fr
                </a>
              </div>
              <div className="contactPro-info">
                <LocalPhoneOutlinedIcon />
                <p className="contactPro-text">01.86.90.63.85</p>
              </div>
            </>
          )}
        </div>
        <div className="contactPro">
          <div className="contactPro-title">NOS HORAIRES</div>
          <div className="contactPro-info">
            Ouvert du Lundi au Jeudi de 9h00 à 18h00 <br /> Le Vendredi de 9h00
            à 17h00
          </div>
        </div>
      </div>
      <div className="footer2">
        Copyright ©2023 2A Consulting. Tous droits réservés.{' '}
        <a href="/mentionslegales">Mentions légales</a>
      </div>
    </div>
  );
};

export default Footer;
