const DataConseil = [
  {
    id: 1,
    title: 'Audit',
    details:
      'Nous réalisons un audit de vos solutions afin de comprendre le fonctionnement de votre société et déterminer ainsi vos habitudes d’utilisation et vos besoins. \n Notre objectif : Proposer une offre complète et adaptée à vos besoins',
  },
  {
    id: 2,
    title: 'Conseil',
    details:
      'A la suite de l’audit et de notre analyse, nous vous faisons des recommandations par rapport à vos problématiques observées et vous conseillons quant à la démarche à suivre.',
  },
  {
    id: 3,
    title: 'Offre personnalisée',
    details:
      'Après l’évaluation de l’ensemble de vos besoins, nous vous préparons une proposition commerciale incluant les caractéristiques des produits, les services inclus, les conditions de financement et vos engagements.',
  },
  {
    id: 4,
    title: 'Installation et formation',
    details:
      'Le déploiement est une étape importante, nous nous engageons à une installation 100% réussie. Notre équipe technique se charge de l’installation, du paramétrage de l’ensemble de la solution choisie et de la formation des utilisateurs sur les nouveaux outils.',
  },
  {
    id: 5,
    title: 'Maintenance',
    details:
      'Tout au long de votre contrat, notre service technique est joignable pour maintenir le bon fonctionnement de vos installations et la continuité de votre travail. En cas de panne, nos ingénieurs et techniciens sont à votre disposition pour une assistance et une prise en main à distance ou sur site dans les plus brefs délais.',
  },
  {
    id: 6,
    title: 'Financement',
    details:
      'Nos partenaires financiers sont spécialisés dans le financement des équipements technologiques. Nous avons sélectionné rigoureusement des établissements réputés et reconnus pour la qualité de leurs services afin de vous aider à concrétiser votre projet. Pour plus de renseignements sur les solutions de financement, n’hésitez pas à nous contacter.',
  },
];

export default DataConseil;
