import './Conseil.css';
import imagePresentation from './conseil.jpg';
import DataConseil from '../../data/DataConseil.js';
import DataValeurs from '../../data/DataValeurs.js';
import ContactUs from '../../components/ContactUs';

const Conseil = () => {
  return (
    <>
      <div className="conseil-presentation">
        <div className="conseil-presentation-texte">
          <div className="conseil-presentation-titre">AUDIT ET CONSEIL</div>
          <div className="conseil-presentation-description">
            Avec plus de 10 ans d&#39;expérience, nous sommes en mesure de vous
            apporter qualité, écoute et conseils. <br /> <br />
            Nous sommes multi métiers et polyvalents. Cela signifie que nous
            vous conseillons et prenons en charge l’ensemble de vos projets
            numériques, même les plus complexes, de A à Z, du financement au
            maintien en conditions opérationnelles. <br /> <br />
            Comprendre vos métiers et vos activités pour vous apporter des
            solutions propres à votre organisation et vous orienter vers de
            nouvelles perspectives d&#39;évolution techniques.
          </div>
        </div>
        <img
          src={imagePresentation}
          alt="Consulting Image"
          class="conseil-image"
        />
      </div>
      <div className="accompagnement-titre-principal-valeurs">NOS VALEURS</div>
      <div className="valeurs">
        {DataValeurs.map((props) => {
          return (
            <>
              <div className="valeurs-component">
                <img
                  src={props.image}
                  alt="pictogramme"
                  className="valeur-image"
                />
                <div className="valeur-titre">{props.title}</div>
              </div>
            </>
          );
        })}
      </div>
      <div className="accompagnement-titre-principal">
        UN SEUL INTERLOCUTEUR POUR UN ACCOMPAGNEMENT SUR MESURE
      </div>
      <div className="accompagnement">
        {DataConseil.map((props) => {
          return (
            <>
              <div className="accompagnement-services">
                <div className="accompagnement-titre">{props.title}</div>
                <div className="accompagnement-description">
                  {props.details}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <ContactUs />
    </>
  );
};

export default Conseil;
