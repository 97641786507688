import './Print.css';
import ContactUs from '../../components/ContactUs';
import produit1 from '../../images/print/produit1.jpg';
import produit2 from '../../images/print/produit2.jpg';
import produit3 from '../../images/print/produit3.jpg';
import CANON from '../../images/print/canon.png';
import HP from '../../images/print/hp.png';
import SHARP from '../../images/print/sharp.png';
import complement from '../../images/print/complement.jpg';

const Print = () => {
  return (
    <>
      <div className="background-image-print">
        <div className="fond-print">IMPRESSION</div>
      </div>
      <div className="description">
        Nous vous conseillons et vous accompagnons dans la mise en place de
        votre système d’impression. <br />
        Nous assurons la maintenance de vos équipements et nous intervenons à
        distance ou sur site en cas de panne.
      </div>
      <div className="titre">NOTRE GAMME DE PRODUITS</div>
      <div className="description">
        Une gamme de produits de haute performance, complète, noir & blanc ou
        couleur, pour répondre à votre besoin et votre budget.
      </div>
      <div className="produits-print">
        <div className="produit-content">
          <div className="image-produit">
            <img src={produit1} alt="produit 1" className="taille-produit" />
          </div>
          <div className="produit-titre">Imprimantes</div>
          <div className="produit-description">
            Impression N&B et/ou Couleur
            <br />
            Impression jusqu'en A4 <br />
            Format compact
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit">
            <img src={produit2} alt="produit 2" className="taille-produit2" />
          </div>
          <div className="produit-titre">Multifonctions</div>
          <div className="produit-description">
            Couleur et Noir & Blanc
            <br />
            Impression jusqu'en A3 <br /> Fonction Scan to Mail
            <br />
            Ecran tactile
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit">
            <img src={produit3} alt="produit 3" className="taille-produit3" />
          </div>
          <div className="produit-titre1">Traceurs</div>
          <div className="produit-description1">
            Impression N&B et/ou Couleur <br /> Impression jusqu'en A0 <br />{' '}
            Impression professionnelle{' '}
          </div>
        </div>
      </div>
      <div className="trait"></div>
      <div className="print-complement">
        <img
          src={complement}
          alt="Femme en train d'imprimer un document"
          className="image-complement"
        />
        <div className="texte-complement">
          <div className="texte-complement1">
            Plusieurs types de prestations sont proposées dans un contrat unique
            avec une équipe qualifiée pour vous assister du début à la fin de
            votre projet. <br />
            <br />
            Avec 2A Consulting, vous bénéficiez de nombreux avantages :
          </div>
          <ul>
            <li>Conseil dans le choix du matériel </li>
            <li>
              Maîtrise des coûts d’impression et optimisation de votre parc
            </li>
            <li>Suivi des consommations et outils de tracking </li>
            <li>
              Simplicité d’utilisation et maintenance complète de votre parc
            </li>
            <li>Amélioration de la productivité de vos collaborateurs </li>
            <li>
              Réduction de l’impact environnemental avec moins de papier, moins
              de déchets et moins de dépenses d’énergie{' '}
            </li>
            <li>Scan et indexation rapide de vos documents à archiver </li>
            <li>
              Impressions sécurisées par code ou badge répondant aux normes RGPD{' '}
            </li>
          </ul>
        </div>
      </div>
      <div className="titre">NOS PARTENAIRES</div>
      <div className="logo-content">
        <img src={CANON} alt="logo CANON" className="canon" />
        <img src={HP} alt="logo HP" className="hp" />
        <img src={SHARP} alt="logo SHARP" className="sharp" />
      </div>
      <ContactUs />
    </>
  );
};

export default Print;
