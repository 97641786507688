import './Informatique.css';
import solution1 from '../../images/info/solution1.jpg';
import solution2 from '../../images/info/solution2.jpg';
import solution3 from '../../images/info/solution3.jpg';
import complement from '../../images/info/complement.jpg';
import APPLE from '../../images/info/apple.png';
import HP from '../../images/info/hp.png';
import MICROSOFT from '../../images/info/microsoft.png';
import ContactUs from '../../components/ContactUs';

const Informatique = () => {
  return (
    <>
      <div className="background-image-info">
        <div className="fond-info">INFORMATIQUE</div>
      </div>
      <div className="description">
        L’informatique est au cœur de vos enjeux de productivité, développez et
        pérennisez votre infrastructure afin de sécuriser votre activité. <br />{' '}
        2A Consulting s’assure pour vous du bon fonctionnement de votre système
        d’information et vous garantit son optimisation constante.
      </div>
      <div className="titre-informatique">NOS SOLUTIONS</div>
      <div className="produits-informatique">
        <div className="produit-content">
          <div className="image-produit-info">
            <img
              src={solution1}
              alt="solution 1"
              className="taille-solution-info"
            />
          </div>
          <div className="produit-titre">Sauvegarde Sécurité</div>
          <div className="produit-description">
            La protection de vos données est
            <br /> indispensable. Soyez protégés des <br />
            menaces informatiques et réseaux.
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit-telecom">
            <img
              src={solution2}
              alt="solution 2"
              className="taille-solution2-info"
            />
          </div>
          <div className="produit-titre">Infogérance Maintenance</div>
          <div className="produit-description">
            2A Consulting intervient sur site ou <br />à distance pour vous
            assurer la gestion <br />
            complète de votre parc informatique
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit-telecom">
            <img
              src={solution3}
              alt="solution 3"
              className="taille-solution3-info"
            />
          </div>
          <div className="produit-titre">Matériel</div>
          <div className="produit-description">
            Notre proximité avec les constructeurs <br /> vous apporte le choix
            et le meilleur prix <br /> pour vos matériels informatiques.
          </div>
        </div>
      </div>
      <div className="trait"></div>
      <div className="tel-complement">
        <div className="texte-complement-content">
          <div className="titre-tel">SOLUTIONS COLLABORATIVES</div>
          <div className="texte-complement-tel">
            Grâce à la multiplication des solutions dématérialisées, le travail
            collaboratif se développe et il apporte une nouvelle façon de
            travailler.
            <br />
            <br />
            Pour vous accompagner dans cette transition, 2A Consulting vous
            propose plusieurs types d’outils de travail collaboratif :
            <ul>
              <li>Ecrans tactiles interactifs </li>
              <li>Ecrans d’affichage dynamique </li>
              <li>Systèmes d’audioconférence et visioconférence </li>
              <li>Logiciels bureautiques Microsoft</li>
              <li>Messagerie instantanée / Partage de fichiers</li>
            </ul>
          </div>
        </div>
        <img
          src={complement}
          alt="Meeting équipe"
          className="image-complement-info"
        />
      </div>
      <div className="titre">NOS PARTENAIRES</div>
      <div className="logo-content-info">
        <img src={APPLE} alt="logo APPLE" className="apple" />
        <img src={MICROSOFT} alt="logo MICROSOFT" className="microsoft" />
        <img src={HP} alt="logo HP" className="hp" />
      </div>
      <ContactUs />
    </>
  );
};

export default Informatique;
