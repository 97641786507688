import './Contact.css';

const Contact = () => {
  return (
    <>
      <div className="background-image-contact">
        <div className="fond-contact">CONTACT</div>
      </div>
      <div className="description">
        Contactez-nous via le formulaire ci-dessous, nous vous répondrons dans
        les meilleurs délais
      </div>
      <form
        action="https://formsubmit.co/23b6c65315adbbf144b57d2478718eaf"
        method="POST">
        <div className="parties-form">
          <input type="text" name="Nom" placeholder="Nom complet *" required />
          <input type="text" name="Societe" placeholder="Société *" required />
        </div>
        <div className="parties-form">
          <input type="email" name="Email" placeholder="Email *" required />
          <input
            type="number"
            name="Telephone"
            placeholder="Telephone *"
            required
          />
        </div>
        <select name="Pourquoi">
          <option>Demande d'informations</option>
          <option>Demande de devis</option>
          <option>Autre</option>
        </select>
        <textarea name="Message" placeholder="Message"></textarea>
        <button type="submit" class="button-envoi">
          Envoyer
        </button>
      </form>
    </>
  );
};

export default Contact;
