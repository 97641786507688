import './Mentions.css';

const Mentions = () => {
  return (
    <>
      <div className="container">
        <div className="conseil-presentation-titre">MENTIONS LEGALES</div>
        <div className="mentions-titre">Identification</div>
        <div className="mentions-description">
          2A Consulting - SIRET 89058162200028 – SASU au capital social de 1000
          € - 2 rue Mathieu - 93400 Saint-Ouen-sur-Seine
          <br /> contact@2a-consulting.fr - 01.86.90.63.85 <br /> Directeur de
          la publication : Alexandre Nakache
          <br /> Hébergeur : <br /> Création du site internet : Laura ZERDOUN
        </div>
        <div className="mentions-titre">Droits d'auteurs</div>
        <div className="mentions-description">
          Le contenu de ce site est protégé par le droit d’auteur au sens des
          articles L. 111-1 et suivants du Code de la propriété intellectuelle.{' '}
          <br /> Toute reproduction totale ou partielle, modification ou
          utilisation est strictement interdite. Toute violation du présent
          article constituerait une contrefaçon, susceptible de sanctions
          pénales et civiles en application des articles L. 335-2 et suivants du
          Code de la propriété intellectuelle
        </div>
        <div className="mentions-titre">Données personnelles</div>
        <div className="mentions-description">
          En cas de fourniture par l’intermédiaire de ce site de données
          personnelles nominatives, ces dernières seront destinées exclusivement
          à la société 2A Consulting et ne pourront être ni revendues ni cédées
          à un tiers.
          <br /> Conformément à la loi « Informatique et Libertés » n° 78-17 du
          6 janvier 1978, vous disposez d’un doit d’accès, de rectification et
          de suppression des données collectées. Vos informations personnelles
          sont conservées par 2A Consulting pour une durée maximale de 3 ans.
        </div>
        <div className="mentions-titre">Cookies</div>
        <div className="mentions-description">
          Afin de faciliter l’utilisation du site, des cookies pourront être
          utilisées. Ils peuvent être désactivés via les options du navigateur.
        </div>
      </div>
    </>
  );
};

export default Mentions;
