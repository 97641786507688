import Card from '../components/Card';
import DataCard from '../data/DataCard.js';
import Presentation from '../components/Presentation';
import ContactUs from '../components/ContactUs';
import Metier from '../components/Metier';

import './Home.css';

const Home = () => {
  return (
    <>
      <Presentation />
      <div className="card-container-global">
        <div className="card-title">NOS PRESTATIONS</div>
        <div className="card-container">
          {DataCard.map((card) => {
            return (
              <Card
                key={card.id}
                title={card.title}
                image={card.picture}
                description={card.details}
                link={card.link}
              />
            );
          })}
        </div>
      </div>

      <Metier />
      <div className="blanc"></div>

      <ContactUs />
    </>
  );
};

export default Home;
