import picto1 from '../images/conseil/picto1.png';
import picto2 from '../images/conseil/picto2.png';
import picto3 from '../images/conseil/picto3.png';
import picto4 from '../images/conseil/picto4.png';

const DataValeurs = [
  {
    id: 1,
    title: 'Confiance',
    image: picto4,
  },
  {
    id: 2,
    title: 'Ecoute',
    image: picto2,
  },
  {
    id: 3,
    title: 'Disponibilité',
    image: picto3,
  },
  {
    id: 4,
    title: 'Réactivité',
    image: picto1,
  },
];

export default DataValeurs;
