import logo from '../../logo.svg';
import './header.css';

import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

import {
  StyledAppBar,
  StyledContainer,
  StyledButton,
  StyledToolbar,
  StyledBurgerMenu,
} from './styles';
import { useTheme, Box, Toolbar, Drawer } from '@mui/material';
import { useState } from 'react';

const HeaderLinks = () => (
  <>
    <StyledButton key={'Accueil'} href="/" classes={{ root: 'styledRoot' }}>
      Accueil
    </StyledButton>
    <StyledButton
      key={'conseil'}
      href="/conseil"
      classes={{ root: 'styledRoot' }}>
      Conseil
    </StyledButton>
    <StyledButton
      key={'Impression'}
      href="/impression"
      classes={{ root: 'styledRoot' }}>
      Impression
    </StyledButton>
    <StyledButton
      key={'Telecom'}
      href="/telecom"
      classes={{ root: 'styledRoot' }}>
      Telecom
    </StyledButton>
    <StyledButton
      key={'Informatique'}
      href="/informatique"
      classes={{ root: 'styledRoot' }}>
      Informatique
    </StyledButton>
    <StyledButton
      key={'Contact'}
      href="/contact"
      classes={{ root: 'styledRoot' }}>
      Contact
    </StyledButton>
    <div className="numero">01.86.90.63.85</div>
  </>
);

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const theme = useTheme();
  const smallScreenMode = useMediaQuery(theme.breakpoints.down('sm'));
  return smallScreenMode ? (
    <>
      <StyledAppBar
        theme={theme}
        position="static"
        classes={{ root: 'styledRoot' }}>
        <Toolbar sx={{ justifyContent: 'space-around' }}>
          <StyledBurgerMenu
            classes={{ root: 'styledRoot' }}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}>
            <MenuIcon />
          </StyledBurgerMenu>
          <a href="/">
            <img src={logo} alt="logo SVG" style={{ height: 50 }} />
          </a>
        </Toolbar>
      </StyledAppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240,
            },
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              alignItems: 'center',
              paddingTop: '15px',
            }}>
            <HeaderLinks />
          </Box>
        </Drawer>
      </Box>
    </>
  ) : (
    <StyledAppBar
      theme={theme}
      position="static"
      classes={{ root: 'styledRoot' }}>
      <StyledContainer maxWidth="xl" classes={{ root: 'styledRoot' }}>
        <div className="Logo">
          <a href="/">
            <img src={logo} className="Logo" alt="logo SVG" />
          </a>
        </div>
        <StyledToolbar disableGutters classes={{ root: 'styledRoot' }}>
          <Box sx={{ display: { md: 'flex' }, gap: '30px' }}>
            <HeaderLinks />
          </Box>
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  );
};

export default Header;

// FAIRE MAP
