import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Conseil from '../pages/Conseil/Conseil';
import Print from '../pages/Print/Print';
import Telecom from '../pages/Telecom/Telecom';
import Informatique from '../pages/Informatique/Informatique';
import Contact from '../pages/Contact/Contact';
import Error from '../pages/Error';
import Mentions from '../pages/Mentions/Mentions';
import { Helmet } from 'react-helmet-async';

function RoutesComponent() {
  return (
    <Switch>
      <Route exact path="/">
        <Helmet>
          <title>
            2a-consulting - Maintenance de photocopieurs, location, services
            télécom et informatique
          </title>
          <meta
            name="description"
            content="Maintenance, location, telecom, informatique et solutions IP pour soutenir votre entreprise. Experts en technologie. Contactez-nous."
          />

          <link rel="canonical" href="/" />
        </Helmet>
        <Home />
      </Route>
      <Route path="/conseil">
        <Helmet>
          <title>
            Audit et Conseil - Solutions personnalisées pour vos projets
            numériques | 2a-consulting
          </title>
          <meta
            name="description"
            content="Conseils personnalisés en audit et solutions numériques. Expérience et expertise pour l'évolution technique. Contactez-nous."
          />

          <link rel="canonical" href="/conseil" />
        </Helmet>
        <Conseil />
      </Route>
      <Route path="/impression">
        <Helmet>
          <title>
            Impression - Conseils, Maintenance et Gamme Complète de
            photocopieurs | 2a-consulting
          </title>
          <meta
            name="description"
            content="Conseils et maintenance pour votre système d’impression. Photocopieurs haute performance adaptés à votre budget. Contactez-nous."
          />

          <link rel="canonical" href="/impression" />
        </Helmet>
        <Print />
      </Route>
      <Route path="/telecom">
        <Helmet>
          <title>
            Télécom - Avantages de la Téléphonie IP et Solutions Adaptées |
            2a-consulting
          </title>

          <meta
            name="description"
            content="Avantages de la téléphonie IP pour les professionnels. Solutions adaptées, lien internet, abonnement et matériel. Contactez-nous."
          />

          <link rel="canonical" href="/telecom" />
        </Helmet>
        <Telecom />
      </Route>
      <Route path="/informatique">
        <Helmet>
          <title>
            Informatique - Assurew le bon fonctionnement de votre système
            d'information avec des solutions sur Mesure | 2a-consulting
          </title>
          <meta
            name="description"
            content="Solutions informatiques pour optimiser votre productivité. Sauvegarde, sécurité, infogérance et maintenance personnalisées. Contactez-nous."
          />

          <link rel="canonical" href="/informatique" />
        </Helmet>
        <Informatique />
      </Route>
      <Route path="/contact">
        <Helmet>
          <title>
            Contact - Conseils et Solutions Personnalisées | 2a-consulting
          </title>
          <meta
            name="description"
            content="Contactez notre équipe pour des conseils et solutions personnalisées en maintenance de photocopieurs, téléphonie, informatique, etc. Nous sommes là pour vous aider."
          />

          <link rel="canonical" href="/contact" />
        </Helmet>
        <Contact />
      </Route>
      <Route path="/mentionslegales">
        <Mentions />
      </Route>
      <Route>
        <Error />
      </Route>
    </Switch>
  );
}

export default RoutesComponent;

// FAIRE MAP DES ROUTES
