import './Telecom.css';
import solution1 from '../../images/tel/solution1.png';
import solution2 from '../../images/tel/solution2.jpg';
import solution3 from '../../images/tel/solution3.jpg';
import complement from '../../images/tel/complement.png';
import ALCATEL from '../../images/tel/alcatel.png';
import BOUYGUES from '../../images/tel/bouygues.png';
import KOSC from '../../images/tel/kosc.png';
import UNYC from '../../images/tel/unyc.png';
import YEALINK from '../../images/tel/yealink.png';
import ContactUs from '../../components/ContactUs';

const Telecom = () => {
  return (
    <>
      <div className="background-image-telecom">
        <div className="fond-telecom">TELECOM</div>
      </div>
      <div className="description">
        La téléphonie IP est devenue depuis 10 ans le nouveau standard mondial.{' '}
        <br /> Elle permet de communiquer sans frais supplémentaires et offre de
        la flexibilité et de nouveaux usages aux professionnels.
      </div>
      <div className="description2">
        La téléphonie d’entreprise s’est modernisée grâce à des avancées
        technologiques et à des accès internet plus puissants.
        <br /> La téléphonie IP est basée sur un modèle de paiement à
        l’utilisateur ou aux nombres de communications simultanées. <br />
        Cette technologie s’adapte donc à l’usage de chaque entreprise.
      </div>
      <div className="titre-telecom">NOS SOLUTIONS</div>
      <div className="produits-telecom">
        <div className="produit-content">
          <div className="image-produit-telecom">
            <img src={solution1} alt="solution 1" className="taille-solution" />
          </div>
          <div className="produit-titre">Lien Internet</div>
          <div className="produit-description">
            Accès Internet le plus rapide et <br />
            le plus fiable en fonction de votre <br />
            emplacement géographique
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit-telecom">
            <img
              src={solution2}
              alt="solution 2"
              className="taille-solution2"
            />
          </div>
          <div className="produit-titre">Abonnement</div>
          <div className="produit-description">
            Multiples fonctionnalités
            <br />
            Appels au compteur ou en illimités <br /> Interface de gestion de
            vos lignes
          </div>
        </div>
        <div className="produit-content">
          <div className="image-produit-telecom">
            <img
              src={solution3}
              alt="solution 3"
              className="taille-solution3"
            />
          </div>
          <div className="produit-titre">Matériel</div>
          <div className="produit-description">
            Nous proposons une gamme
            <br />
            complète d’équipements pour vos
            <br /> solutions de téléphonie et de réseau{' '}
          </div>
        </div>
      </div>
      <div className="trait"></div>
      <div className="tel-complement">
        <div className="texte-complement-content">
          <div className="titre-tel">COMMUNICATIONS UNIFIEES</div>
          <div className="texte-complement-tel">
            Appels, visios, messagerie, vos moyens de communication sont
            concentrés en une seule application.
            <br />
            <br />
            Compatible avec tous types de terminaux (PC, Mac, Android et iOS),
            notre solution de communication unifiée vous accompagne où vous le
            souhaitez.
            <br />
            <br />
            Vos appels et messages sont synchronisés en temps réel sur tous vos
            appareils.
            <br />
            <br />
            Au bureau ou à l’extérieur, vous êtes toujours joignable sur votre
            numéro fixe.
          </div>
        </div>
        <img
          src={complement}
          alt="Communication ordianteur/msartphone"
          className="image-complement-tel"
        />
      </div>
      <div className="titre">NOS PARTENAIRES</div>
      <div className="logo-content-tel">
        <img src={ALCATEL} alt="logo ALCATEL" className="alcatel" />
        <img src={BOUYGUES} alt="logo BOUYGUES" className="bouygues" />
        <img src={KOSC} alt="logo KOSC" className="kosc" />
        <img src={UNYC} alt="logo UNYC" className="unyc" />
        <img src={YEALINK} alt="logo YEALINK" className="yealink" />
      </div>
      <ContactUs />
    </>
  );
};

export default Telecom;
