import print from '../components/Card/pictures/print.svg';
import telecom from '../components/Card/pictures/telecom.svg';
import informatique from '../components/Card/pictures/informatique.svg';

const DataCard = [
  {
    id: 1,
    title: 'Impression',
    picture: print,
    link: '/impression',
    details:
      'Nos équipes vous apportent leurs compétences pour vous conseiller le meilleur équipement de systèmes d’impression avec le service associé. \n Notre objectif : Proposer une offre complète et adaptée à vos besoins',
  },
  {
    id: 2,
    title: 'Telecom',
    picture: telecom,
    link: '/telecom',
    details:
      '2A Consulting vous accompagne dans la mise en place de solutions de téléphonie sur IP, la fourniture de lien internet très haut débit, la gestion de votre parc de téléphonie mobile et une installation sur mesure.',
  },
  {
    id: 3,
    title: 'Informatique',
    picture: informatique,
    link: '/informatique',
    details:
      'L’informatique est au cœur de vos enjeux de productivité, c’est pourquoi 2A Consulting vous accompagne dans le conseil, le maintien en condition opérationnel et l’évolution de votre système d’information.',
  },
];

export default DataCard;
