import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const StyledDiv = withTheme(styled('div')`
  height: calc(100vh - 56px);
  margin-top: 56px;
  overflow-x: hidden;
  overflow-y: auto;
  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-right: 50px;
    padding-left: 50px;
    height: calc(100vh - 118px);
    margin-top: 108px;
  }
`);

export default StyledDiv;
