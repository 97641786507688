import './styles.css';
import MetierImg from './metier-image.jpg';
import { Button, useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';

const Metier = () => {
  const theme = useTheme();
  const smallScreenMode = useMediaQuery(theme.breakpoints.down('sm'));
  return smallScreenMode ? (
    <div
      className="metier-global"
      style={{ padding: '0 5vw', flexDirection: 'column', height: 'auto' }}>
      <div className="metier-texte" style={{ width: 'auto' }}>
        <h1 className="metier-title">QUI SOMMES-NOUS ?</h1>
        <img
          src={MetierImg}
          style={{ borderRadius: '35px' }}
          alt="metier image"
        />
        <p className="metier-description">
          2A Consulting vous propose une large gamme de solutions
          professionnelles en vente ou à la location. <br />
          <br />
          Spécialiste de la transformation digitale, son équipe se tient à votre
          disposition afin de vous fournir la meilleure solution adaptée à vos
          besoins.
          <br />
          <br />
          Notre mission : vous permettre d'atteindre vos objectifs et booster
          votre croissance, tout en
          <br />
          vous apportant confort et simplicité au quotidien.
        </p>
        <Button size="middle" href="/conseil">
          En savoir plus
        </Button>
      </div>
    </div>
  ) : (
    <div className="metier-global">
      <div className="metier-texte">
        <h1 className="metier-title">QUI SOMMES-NOUS ?</h1>
        <p className="metier-description">
          2A Consulting vous propose une large gamme de solutions
          professionnelles en vente ou à la location. <br />
          <br />
          Spécialiste de la transformation digitale, son équipe se tient à votre
          disposition afin de vous fournir la meilleure solution adaptée à vos
          besoins.
          <br />
          <br />
          Notre mission : vous permettre d'atteindre vos objectifs et booster
          votre croissance, tout en
          <br />
          vous apportant confort et simplicité au quotidien.
        </p>
        <Button size="middle" href="/conseil">
          En savoir plus
        </Button>
      </div>
      <img src={MetierImg} className="metier-image" alt="metier image" />
    </div>
  );
};

export default Metier;
