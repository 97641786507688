import * as React from 'react';
import './styles.css';

import { StyledCardMedia, StyledButton } from './styles';
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from '@mui/material';

export default function CardSolution(props) {
  return (
    <StyledButton classes={{ root: 'styledRoot' }} href={props.link}>
      <Card sx={{ maxWidth: 350, height: 450 }}>
        <StyledCardMedia
          image={props.image}
          title={props.title}
          classes={{ root: 'styledRoot' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.description}
          </Typography>
        </CardContent>
        <CardActions class="savoir-plus">
          <Button size="middle" href={props.link}>
            En savoir plus
          </Button>
        </CardActions>
      </Card>
    </StyledButton>
  );
}
