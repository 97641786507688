import { Button, CardMedia } from '@mui/material';

import styled from 'styled-components';

export const StyledCardMedia = styled(CardMedia)`
  &.styledRoot {
    background-size: contain;
    margin: 15px;
    height: 140px;
    text-decoration: none;
  }
`;

export const StyledButton = styled(Button)`
  &.styledRoot {
    text-transform: inherit;
  }
  }
`;
