import { AppBar, Container, Button, Toolbar, IconButton } from '@mui/material';

import styled from 'styled-components';

export const StyledAppBar = styled(AppBar)`
  &.styledRoot {
    background-color: white;
    position: fixed;
    top: 0px;
  }
`;

export const StyledContainer = styled(Container)`
  &.styledRoot {
    display: flex;
    justify-content: space-between;
    padding: 9px 25px;
  }
`;

export const StyledButton = styled(Button)`
  &.styledRoot {
    my: 2;
    color: #503c8c;
    display: block;
    font-size: 16px;
    padding: 0;
    font-family: 'FuturaTitle';
  }
`;

export const StyledToolbar = styled(Toolbar)`
  &.styledRoot {
    align-items: center;
  }
`;

export const StyledBurgerMenu = styled(IconButton)`
  &.styledRoot {
    justify-content: start;
    > svg {
      width: 28px;
      height: 28px;
    }
  }
`;
