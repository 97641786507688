import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import RoutesComponent from './components/RoutesComponent';
import Header from './components/Header';
import Footer from './components/Footer';
import GlobalContainer from './components/GlobalContainer';
import './App.css';
import './fonts/Futura Heavy font.ttf';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <GlobalContainer>
          <RoutesComponent />
          <Footer />
        </GlobalContainer>
      </ThemeProvider>
    </>
  );
}

export default App;
